<template>
    <div class="tit-wrap--x-small">
        <div class="pb-8px pb-md-12px">
            <v-row align="center" class="row--sm">
                <div class="col col-auto">
                    <u-txt-medium v-if="this.$slots['titleContents']" class="txt--sm txt--dark"><slot name="titleContents" /></u-txt-medium>
                    <u-txt-medium v-else class="txt--sm txt--dark">{{ title }}</u-txt-medium>
                </div>
                <v-spacer v-if="this.$slots['default']" />
                <div v-if="this.$slots['default']" class="col col-auto">
                    <slot />
                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {
        title: { type: String },
    },
    components: {
        UTxtDefault,
        UTxtMedium,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
